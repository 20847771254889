exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-wp-post-uri-js": () => import("./../../../src/pages/blog/{wpPost.uri}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-uri-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wp-page-link-js": () => import("./../../../src/pages/{wpPage.link}.js" /* webpackChunkName: "component---src-pages-wp-page-link-js" */),
  "component---src-pages-wp-proyecto-uri-js": () => import("./../../../src/pages/{wpProyecto.uri}.js" /* webpackChunkName: "component---src-pages-wp-proyecto-uri-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-archive-portafolio-js": () => import("./../../../src/templates/archivePortafolio.js" /* webpackChunkName: "component---src-templates-archive-portafolio-js" */)
}

